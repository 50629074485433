<template>
    <div class="dashboard">
        <div class="card">
            <div class="card-header d-flex">
              <h2 class="mr-auto">{{$trans('Dashboard')}}</h2>
              <div class="d-flex flex-row-reverse">
                <select v-model="filterQuery.filterby" class="custom-select w150" @change="triger_filter">
                  <option value="today">{{$trans('Today')}}</option>
                  <option value="yesterday">{{$trans('Yesterday')}}</option>
                  <option value="month">{{$trans('This Month')}}</option>
                  <option value="last_month">{{$trans('Last Month')}}</option>
                  <option value="year">{{$trans('This Year')}}</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4">
                <div  class="box-count">
                  <div class="icon">
                    <svg  viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line data-v-4283712e="" x1="12" y1="1" x2="12" y2="23"></line><path data-v-4283712e="" d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
                  </div>
                  <div class="content">
                    <h6>{{$trans('Revenue')}}</h6>
                    <span>
                      <span class="">
                        <vue-numeric class='tex'
                                     currency-symbol-position="suffix"
                                     :currency="'PHP'"
                                     v-bind:value="report_data.revenue"
                                     v-bind:read-only="true"
                                     v-bind:precision="0">
                        </vue-numeric>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="box-count">
                  <div class="icon bg-success">
                    <svg data-v-7d622f5c="" viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><polygon data-v-7d622f5c="" points="12 2 2 7 12 12 22 7 12 2"></polygon><polyline data-v-7d622f5c="" points="2 17 12 22 22 17"></polyline><polyline data-v-7d622f5c="" points="2 12 12 17 22 12"></polyline></svg>
                  </div>
                  <div class="content">
                    <h6>{{$trans('Orders')}}</h6>
                    <span>
                         <vue-numeric class='tex'
                            currency-symbol-position="suffix"
                            currency=""
                            v-bind:value="report_data.total_order"
                            v-bind:read-only="true"
                            v-bind:precision="0">
                        </vue-numeric>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <slot v-if="results && results.length && order_revenue_chart">
                <GChart
                    type="LineChart"
                    :data="report_data.revenue_chart_data"
                    :options="order_revenue_chart.options"
                />
              </slot>
              <slot v-else>
                {{$trans('No data')}}
              </slot>
            </div>
        </div>
    </div>
</template>

<script>
  import axios from "axios";
    export default {
        name: "Dashboard",
        data:function () {
            return {
              filterQuery:{
                filterby:'today'
              },
              statics_data:null,
              results:null,
              order_revenue_chart:null
            }
        },
        methods:{
          triger_filter:function(){
            this.get_statistic();
          },
          get_statistic:function (){
            axios({url: this.$root.$data.api_url + '/api/customer/statistic', params: this.filterQuery, method: 'GET'})
                .then(resp => {
                  this.statics_data = resp.data.data;
                  this.results = resp.data.results;
                  this.order_revenue_chart = resp.data.order_revenue_chart;
                })
                .catch(err => {
                  console.log(err)
                })
          }
        },
        created() {
            this.triger_filter()
        },
        computed:{
          report_data:function (){
            if (!this.results) {
              return [];
            }
            let data = this.results
            let revenue_chart_data = this.order_revenue_chart.data;
            let total_order = 0;
            let revenue = 0;
            data.forEach((el)=>{
              total_order = total_order +el.total_order;
              revenue = revenue + el.revenue
              let item_revenue_chart_data = [
                el.vAxisTitle,
                el.revenue,
                el.total_order,
              ]
              revenue_chart_data.push(item_revenue_chart_data);

            })
            return {
              revenue_chart_data:revenue_chart_data,
              total_order:total_order,
              revenue:revenue,
            }
          }
        }
    }
</script>

<style scoped>

</style>