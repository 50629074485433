import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import Axios from 'axios'

import AxiosPlugin from 'vue-axios-cors';
Vue.use(AxiosPlugin)

import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'

import device from "vue-device-detector"

Vue.use(device)

import Select2 from 'v-select2-component';

Vue.component('Select2', Select2);

import VueGoogleCharts from 'vue-google-charts'

Vue.use(VueGoogleCharts)

// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/css/style.css'
import './assets/fontawesome/css/all.css'

import Paginate from 'vuejs-paginate'

Vue.component('paginate', Paginate)
import VueNumeric from 'vue-numeric'

Vue.use(VueNumeric)

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
Vue.use(DatePicker)


Vue.prototype.$http = Axios;
const token = localStorage.getItem('token')
if (token) {
    Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer ' + token
}
Vue.prototype.$http.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
Vue.prototype.$http.defaults.headers.common['Content-type'] = 'application/json'

const lang = localStorage.getItem('lang');

if (lang) {
    Vue.prototype.$http.defaults.headers.common['language'] = lang;
}
const currency = localStorage.getItem('currency');

if (currency) {
    Vue.prototype.$http.defaults.headers.common['currency'] = currency;
}
import VueGallery from 'vue-gallery';
Vue.use(VueGallery)
Vue.component('gallery', VueGallery)

import i18n from './components/mixins/i18n.vue';
Vue.mixin(i18n);
import Helpers from './components/mixins/Helpers.vue';

Vue.mixin(Helpers);

Vue.component('sidebar', require('./components/Sidebar.vue').default);
Vue.component('modal', require('./components/Modal.vue').default);
Vue.component('system-settings', require('./components/settings/System.vue').default);
Vue.component('merchant-settings', require('./components/Merchant.vue').default);

import VueHtmlToPaper from 'vue-html-to-paper';
let basePath= 'http://localhost:8080';
const options = {
    name: '_blank',
    specs: [
        'fullscreen=yes',
        'titlebar=no',
        'scrollbars=yes'
    ],
    styles: [
        'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
        'https://unpkg.com/kidlat-css/css/kidlat.css',
        './assets/css/print.css',
        `${basePath}/print.css`
    ]
}

Vue.use(VueHtmlToPaper, options);
import * as GmapVue from 'gmap-vue'

Vue.use(GmapVue, {
    load: {
        key: process.env.VUE_APP_GMAP_API,
        libraries: 'places', // This is required if you use the Autocomplete plugin
    },
})
import GmapCustomMarker from 'vue2-gmap-custom-marker';
Vue.component('gmap-custom-marker', GmapCustomMarker);
import VueBarcode from 'vue-barcode';
Vue.component('barcode',VueBarcode)

new Vue({
    render: h => h(App),
    router,
    store,
    data: function () {
        return {
            api_url: process.env.VUE_APP_API_URL,
            app_name: process.env.VUE_APP_NAME,
            global_settings:{}
        }
    },
    methods: {
        get_global_settings:function (){
            Axios({url: this.$root.$data.api_url + '/api/settings', params: {}, method: 'GET'})
                .then(resp => {
                    this.global_settings = resp.data;
                })
                .catch(err => {
                    console.log(err)
                })
        },
    },
    computed:{
        isMobile: function () {
            return this.$device.mobile;
        },
        current_currency:function () {
            return this.$store.getters.currency;
        }
    },
    created() {
        Axios.interceptors.response.use((response) => {
            return response;
        }, (error) => {
            if (error.response) {
                if (error.response.status === 401) {
                    this.$store.dispatch('logout')
                    this.$router.push('/login')
                }
            }
            throw  error;
        })
        this.get_global_settings();
    }
}).$mount('#app')
