<template>
  <div class="auth-main">
    <div class="form login-form">
      <form class="login" @submit.prevent="login">
        <h1>{{$trans('Sign in')}}</h1>
        <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
          {{alert.message}}
        </div>
        <div class="form-group">
          <label>{{$trans('Username')}}</label>
          <input class="form-control"  v-model="username" type="text"/>
        </div>
        <div class="form-group">
          <label>{{$trans('Password')}}</label>
          <input class="form-control" required v-model="password" type="password"/>
        </div>
        <div class="form-group">
          <div class="form-check">
            <input id="remember" type="checkbox" v-model="remember">
            <label class="form-check-label" for="remember">
              {{$trans('Remember me')}}
            </label>
          </div>
        </div>
        <div class="d-flex">
          <button class="btn btn-primary mr-auto" type="submit">{{$trans('Login')}}</button>
          <div class="extra-link">
            <router-link to="/forgot-password">{{$trans('Forgot Your Password?')}}</router-link>
            <router-link to="/register">{{$trans('Register')}}</router-link>
          </div>
        </div>
      </form>
    </div>
  </div>

</template>

<script>
    export default {
        name: "Login",
        data: function () {
            return {
                email: '',
                password: '',
                alert: null,
                process: false,
                remember:false,
                username:''
            }
        },
        methods: {
            login: function () {
                if (this.process == false) {
                    this.process = true;
                    this.alert = null;
                    let remember = this.remember
                    let password = this.password
                    let username = this.username
                    this.$store.dispatch('login', {username, password,remember})
                        .then(() => {
                            this.process = false;
                            this.$router.push('/')
                        })
                        .catch(err => {
                            this.process = false;
                            this.errors = err.data.errors || {};
                            this.alert = {
                                type: 'warning',
                              message: err.data.message
                            }
                        })
                }

            }
        },
        created() {
            if( this.$root.isLoggedIn){
                this.$router.push('/')
            }
        }
    }
</script>

<style scoped>

</style>