<template>
    <div class="add-order card">
        <div class="card-header">
            <h2>{{$trans('Add Order')}}</h2>
        </div>
        <div class="card-body">
          <small>{{$trans('Fields Marked with (*) are required')}}</small>
          <hr>
          <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
            {{alert.message}}
          </div>
          <div class="row">
              <div class="col-sm-6">
                  <h3 class="form-head">
                    {{$trans('Sender')}}
                    <a @click="showUserAddresses = true" class="ml-2 text-primary"
                       href="javascript:void(0)">
                      <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor"
                           stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"
                           class="css-i6dzq1">
                        <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                        <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                      </svg>
                    </a>
                  </h3>
                <div v-if="addFields.pickup_address" class="pickup_address mb-3">
                  <strong>{{addFields.pickup_address.name}}</strong> -
                  <strong>{{addFields.pickup_address.phone}}</strong>
                  <div class="full-address">
                    {{addFields.pickup_address.address}}, {{addFields.pickup_address.province.name}},
                    {{addFields.pickup_address.district.name}}, {{addFields.pickup_address.ward.name}}
                  </div>
                </div>
                <div class="form-group">
                  <div class="form-check">
                    <input disabled v-model="addFields.pickup_type" class="form-check-input"
                           type="checkbox"
                           true-value="office" false-value="home">
                    <label @click="showOfficeAddresses = true" class="form-check-label">
                      {{$trans('Delivery at delivery point of iShipee')}} <span
                        v-b-tooltip.hover.topright="$trans('The sender wants to go to the nearest post office to send the goods without staff picking up the goods.')"><i
                        class="fas fa-question-circle fz-12"></i></span>
                    </label>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "Add",
  data:function (){
      return {
        showUserAddresses:false,
        addFields: {
          pickup_address: null,
          destination_address: {
            name: '',
            phone: '',
            address: '',
            province_id: 0,
            district_id: 0,
            ward_id: 0
          },
          packages: [
            {
              name: 'Package 01',
              type: 'weight',
              value: 0.5
            }
          ],
          round_trip: 'no',
          express_delivery: 'no',
          fee_payer: 'receiver',
          note: '',
          pickup_type: 'home',
          cod_amount: 0
        },
      }
  }
}
</script>

<style scoped>

</style>