<template>
    <div>
        <div class="sidebar-head">
          <router-link to="/">
            <img :src="require('../assets/logo.png')" alt="">
          </router-link>
        </div>
        <div class="user-info">
            <router-link to="/profile">
                <span class="avatar-logo">
                    <svg viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                </span>
                 <span class="info">
                    <span class="name">{{user}}</span>
                    <span  class="sub">
                        <span v-if="role" class="badge badge-success">{{role}}</span>
                        <span class="badge badge-success" v-else>{{$trans('Client')}}</span>
                    </span>
                </span>
            </router-link>
        </div>
        <div class="sidebar-menu-content">
            <ul class="sidebar-menu">
              <li>
                <router-link to="/">
                  <span class="icon">
                      <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><rect x="3" y="3" width="7" height="7"></rect><rect x="14" y="3" width="7" height="7"></rect><rect x="14" y="14" width="7" height="7"></rect><rect x="3" y="14" width="7" height="7"></rect></svg>
                  </span>
                  <span class="text">{{$trans('Dashboard')}}</span>
                </router-link>
              </li>
                <li>
                  <router-link to="/orders">
                    <span class="icon">
                        <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><rect x="4" y="4" width="16" height="16" rx="2" ry="2"></rect><rect x="9" y="9" width="6" height="6"></rect><line x1="9" y1="1" x2="9" y2="4"></line><line x1="15" y1="1" x2="15" y2="4"></line><line x1="9" y1="20" x2="9" y2="23"></line><line x1="15" y1="20" x2="15" y2="23"></line><line x1="20" y1="9" x2="23" y2="9"></line><line x1="20" y1="14" x2="23" y2="14"></line><line x1="1" y1="9" x2="4" y2="9"></line><line x1="1" y1="14" x2="4" y2="14"></line></svg>
                    </span>
                    <span class="text">{{$trans('Orders')}}</span>
                  </router-link>
                </li>
<!--                <li>
                  <router-link to="/address">
                      <span class="icon">
                          <svg data-v-4049db24="" viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path data-v-4049db24="" d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline data-v-4049db24="" points="15 3 21 3 21 9"></polyline><line data-v-4049db24="" x1="10" y1="14" x2="21" y2="3"></line></svg>
                      </span>
                    <span class="text">{{$trans('Address')}}</span>
                  </router-link>
                </li>-->
                <li>
                    <router-link to="/settings">
                        <span class="icon">
                            <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z"></path></svg>
                        </span>
                        <span class="text">{{$trans('Settings')}}</span>
                    </router-link>
                </li>
                <li class="menu-item">
                  <a href="javascript:void(0)" @click="logout">
                     <span class="icon">
                        <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
                    </span>
                    <span>{{$trans('Logout')}}</span>
                  </a>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
    export default {
        name: "Sidebar",
        data:function () {
            return {
              app_name:'iShipee.com',
            }
        },
      methods:{
        logout: function () {
          this.$store.dispatch('logout')
              .then(() => {
                this.$router.push('/login')
              })
        },
        change_currency:function (event){
          this.$store.dispatch('changeCurrency', event.target.value)
        }
      },
      computed:{
          user:function () {
              return this.$store.getters.user
          },
          role:function (){
            let roles = JSON.parse(localStorage.getItem('roles'))
            if( ! roles){
                return  '-';
            }
            return  roles[0];
          },
      }
    }
</script>

<style scoped>

</style>