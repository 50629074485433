<template>
  <div class="address card">
    <div class="card-header d-flex">
      <h2 class="mr-auto">{{ $trans('Address') }}</h2>
      <button @click="addForm = true" class="btn btn-primary btn-sm">
        <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none"
             stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
          <line data-v-728e5abc="" x1="12" y1="5" x2="12" y2="19"></line>
          <line data-v-728e5abc="" x1="5" y1="12" x2="19" y2="12"></line>
        </svg>
        {{ $trans('Add new') }}
      </button>
    </div>
    <div class="card-body">
      <table v-if="addresses" class="table table-striped">
        <thead>
        <tr>
          <th>{{$trans('Name')}}</th>
          <th>{{$trans('Phone')}}</th>
          <th>{{$trans('Address')}}</th>
          <th>{{$trans('Postcode')}}</th>
          <th class="text-right">{{$trans('Actions')}}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(add,index) in addresses" v-bind:key="index">
          <td>{{add.name}}</td>
          <td>{{add.phone}}</td>
          <td>{{add.address}}</td>
          <td>

          </td>
          <td class="text-right">
            <b-dropdown
                split
                split-variant="outline-primary"
                variant="primary"
                :text="$trans('Actions')"
                class="m-2"
                size="sm"
            >
              <slot>
                <b-dropdown-item  @click="edit(add)" href="#">{{$trans('Edit')}}</b-dropdown-item>
                <b-dropdown-item  @click="destroy(add)" href="#">{{$trans('Delete')}}</b-dropdown-item>
              </slot>
            </b-dropdown>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <modal v-if="addForm" v-bind:width="'500px'" v-bind:height="'100vh'">
      <slot slot="body">
        <h5 class="mb-3">
          {{ $trans('Add address') }}
          <button @click="addForm = false" class="close">
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none"
                 stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </h5>
        <small>{{ $trans('Fields Marked with (*) are required') }}</small>
        <hr>
        <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
          {{ alert.message }}
        </div>
        <div class="form-group">
          <label>{{ $trans('Name') }} (*)</label>
          <input v-model="addFields.name" class="form-control" type="text">
          <slot v-if="errors && errors['name']">
            <div class="text-danger" v-for="error in errors['name']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group">
          <label>{{ $trans('Phone') }} (*)</label>
          <input v-model="addFields.phone" class="form-control" type="text">
          <slot v-if="errors && errors['phone']">
            <div class="text-danger" v-for="error in errors['phone']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group">
          <label>{{ $trans('House/Unit/Flr #, Bldg Name, Blk or Lot #') }} (*)</label>
          <input v-model="addFields.address" class="form-control" type="text">
          <slot v-if="errors && errors['address']">
            <div class="text-danger" v-for="error in errors['address']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group">
          <label>{{ $trans('Place') }} (*)</label>
          <Select2 v-model="addFields.place_id"   :settings="address_search_settings"  />
          <slot v-if="errors && errors['place_id']">
            <div class="text-danger" v-for="error in errors['place_id']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <button @click="store" class="btn btn-primary">
          <i v-if="process" class="fa fa-spinner fa-spin"></i>
          {{ $trans('Add') }}
        </button>
      </slot>
    </modal>
  </div>

</template>

<script>
import axios from "axios";
export default {
  name: "Address",
  data: function () {
    return {
      addForm: false,
      alert: null,
      process: false,
      addFields: {
        name: '',
        phone: '',
        address: '',
        place_id: '',
        place: '',
      },
      errors:{},
      address_search_settings:{
        ajax:{
          url: this.$root.$data.api_url + '/api/address/search',
          dataType: 'json',
          processResults: function (data) {
            let addresses = data.predictions;
            let results=[]
            addresses.forEach((el)=>{
              let item ={
                id:el.place_id,
                text:el.description
              }
              results.push(item)
            })
            return {results:results}
          },
        },
        multiple:false,
        placeholder:'Enter keyword here!',
        minimumInputLength:3
      },
      addresses:null
    }
  },
  methods: {
    index:function (){
      axios({url: this.$root.$data.api_url + '/api/customer/addresses',params:{}, method: 'GET'})
          .then(resp => {
            this.addresses = resp.data;
          })
          .catch(err => {
            console.log(err)
          })
    },
    store: function () {
      if( this.process == false){
        this.process = true;
        this.errors = {}
        this.alert = null
        this.addFields.rules = this.fees;
        axios({url: this.$root.$data.api_url + '/api/customer/addresses', data:this.addFields,method: 'POST'})
            .then(resp => {
              let error_code = resp.data.error_code;
              if( error_code == 0){
                this.addForm = false;
                this.index();
              }else{
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.process = false;
            })
            .catch(err => {
              console.log(err)
              this.process = false;
            })
      }
    },
    edit:function (){

    },
    update:function (){

    },
    destroy:function (){

    }
  },
  created() {
    this.index();
  }
}
</script>

<style scoped>

</style>