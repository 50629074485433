<template>
  <div class="tracking">
    <slot v-if="order">
      <div class="row">
        <div class="col-sm-8">
          <GmapMap :center="destination_locate" :zoom="18" map-type-id="roadmap" style="width: 100%; height: calc(100vh - 80px)">
            <gmap-custom-marker v-if="shipper_locate && display_shipper_locate" class="marker shipper-marker" style="" :marker="shipper_locate" alignment="top">
              <div class="icon"></div>
            </gmap-custom-marker>
            <gmap-custom-marker class="marker pickup-marker" style="" :marker="pickup_locate" alignment="top">
              <div class="icon"></div>
            </gmap-custom-marker>
            <gmap-custom-marker class="marker destination-marker" style="" :marker="destination_locate" alignment="top">
              <div class="icon"></div>
            </gmap-custom-marker>
          </GmapMap>
        </div>
        <div class="col-sm-4">
          <div class="card">
            <div class="card-body">
              <h6>{{$trans('Tracking log')}}</h6>
              <hr>
              <slot v-if="order.tracking_histories">
                <span v-for="(log,l) in order.tracking_histories" :key="l" class="d-block">{{log}}</span>
              </slot>
            </div>
          </div>
        </div>
      </div>

    </slot>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Tracking",
  props: {
    order_id: {
      required: true,
      type: String
    }
  },
  data: function () {
    return {
      order: null,
      history: [],
      markers: []
    }
  },
  methods: {
    tracking: function () {

      axios({url: this.$root.$data.api_url + '/api/tracking/' + this.order_id, params: {}, method: 'GET'})
          .then(resp => {
            var error_code = resp.data.error_code;
            if (error_code == 0) {
              this.order = resp.data.data;
            }

          })
          .catch(err => {
            console.log(err)
          })
    }
  },
  created() {
    this.tracking();
  },
  computed: {
    shipper_locate: function () {
      if (!this.order) {
        return [];
      }
      if (!this.order.shipper) {
        return []
      }
      return {
        lat: parseFloat(this.order.shipper.lat),
        lng: parseFloat(this.order.shipper.lng)
      }
    },
    pickup_locate:function (){
      if (!this.order) {
        return [];
      }
      if (!this.order.pickup_address) {
        return []
      }
      return {
        lat: parseFloat(this.order.pickup_address.lat),
        lng: parseFloat(this.order.pickup_address.lng)
      }
    },
    destination_locate:function (){
      if (!this.order) {
        return [];
      }
      if (!this.order.destination_address) {
        return []
      }
      return {
        lat: parseFloat(this.order.destination_address.lat),
        lng: parseFloat(this.order.destination_address.lng)
      }
    },
    display_shipper_locate:function (){
      if( this.order && this.order.status == 3){
        return  true
      }
      return false
    }
  }
}
</script>

<style scoped>

</style>