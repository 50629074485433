<template>
  <div class="orders">
    <div class="card-header d-flex">
      <h2 class="mr-auto ">{{ $trans('Orders') }}</h2>
      <router-link class="btn btn-primary btn-sm" to="/orders/add">
        <svg data-v-728e5abc="" viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line data-v-728e5abc="" x1="12" y1="5" x2="12" y2="19"></line><line data-v-728e5abc="" x1="5" y1="12" x2="19" y2="12"></line></svg>
        {{$trans('Add new')}}
      </router-link>
    </div>
    <div class="d-flex mb-3">
      <input @change="filter" v-model="filterQuery.keyword" type="text" placeholder="Order ID"
             class="form-control w-300 mr-3">
      <select @change="filter" v-model="filterQuery.status" class="form-control w-100 mr-3">
        <option value="all">Status</option>
        <option value="0">Initialization</option>
        <option value="1">Processing</option>
        <option value="2">Received</option>
        <option value="3">On Delivery</option>
        <option value="4">Delivered</option>
        <option value="5">Completed</option>
      </select>
      <select @change="filter" v-model="filterQuery.filterby_date" class="form-control w-100 mr-3">
        <option value="all">All date</option>
        <option value="today">Today</option>
        <option value="week">This Week</option>
        <option value="month">This Month</option>
        <option value="year">This Year</option>
        <option value="range">Range date</option>
      </select>
    </div>
    <div class="card-body">
      <slot v-if="orders && orders.data">
        <div class="table-responsive">
          <div class="list-info mb-3">
            <small>{{ $trans('Results') }}: <strong>{{ orders.data.length }}</strong> {{ $trans('of') }}
              <strong>{{ orders.total }}</strong></small>
          </div>
          <table class="table table-hover">
            <thead>
            <tr>
              <th class="nowrap">{{ $trans('Order ID') }}</th>
              <th class="nowrap text-right">{{ $trans('COD Amount') }}</th>
              <th class="nowrap">{{ $trans('Client Order ID') }}</th>
              <th class="nowrap">{{ $trans('Pickup Type') }}</th>
              <th class="nowrap">{{ $trans('Pickup address') }}</th>
              <th class="nowrap">{{ $trans('Destination address') }}</th>
              <th class="nowrap">{{ $trans('Cost') }}</th>
              <th class="nowrap">{{ $trans('Status') }}</th>
              <th class="nowrap">{{ $trans('Date') }}</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(order,index) in orders.data" v-bind:key="index">
              <td>{{ order._id }}</td>
              <td class="nowrap text-right">
                <vue-numeric
                    currency-symbol-position="suffix"
                    currency="PHP"
                    v-bind:value="order.cod_amount"
                    v-bind:read-only="true"
                    v-bind:precision="0">
                </vue-numeric>
              </td>
              <td class="nowrap">{{ order.order_id }}</td>
              <td class="text-uppercase">{{ order.pickup_type }}</td>

              <td>
                {{ order.pickup_address.name }} - {{ order.pickup_address.phone }}<br>
                {{ order.pickup_address.address }} {{ order.pickup_address.place }}
              </td>
              <td>
                {{ order.destination_address.name }} - {{ order.destination_address.phone }}<br>
                {{ order.destination_address.address }} {{ order.destination_address.place }}
              </td>
              <td class="nowrap text-right">
                <vue-numeric
                    currency-symbol-position="suffix"
                    currency="PHP"
                    v-bind:value="order.delivery_fee.total"
                    v-bind:read-only="true"
                    v-bind:precision="0">
                </vue-numeric>
              </td>
              <td>
                <span v-if="order.status == -3" class="badge badge-primary badge-pill returned">{{ $trans('Returned') }}</span>
                <span v-if="order.status == -2" class="badge badge-warning badge-pill returning">{{ $trans('Returning') }}</span>
                <span v-if="order.status == -1" class="badge badge-danger badge-pill cancel">{{ $trans('Cancel') }}</span>
                <span v-if="order.status == 0" class="badge badge-default badge-pill initialization">{{ $trans('Initialization') }}</span>
                <span v-if="order.status == 1" class="badge badge-info badge-pill processing">{{ $trans('Processing') }}</span>
                <span v-if="order.status == 2" class="badge badge-success badge-pill received">{{ $trans('Received') }}</span>
                <span v-if="order.status == 3" class="badge badge-success badge-pill on-delivery">{{ $trans('On Delivery') }}</span>
                <span v-if="order.status == 4" class="badge badge-success badge-pill delivered">{{ $trans('Delivered') }}</span>
                <span v-if="order.status == 5" class="badge badge-success badge-pill completed">{{ $trans('Completed') }}</span>
              </td>
              <td class="nowrap">{{ order.created_at }}</td>
              <td class="text-right">
                <b-dropdown
                    split
                    split-variant="outline-primary"
                    variant="primary"
                    :text="$trans('Actions')"
                    class="m-2"
                    size="sm"
                >
                  <slot>
                    <b-dropdown-item @click="detail(order)"   href="#">{{$trans('Detail')}}</b-dropdown-item>
                  </slot>
                </b-dropdown>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <paginate v-if="orders && orders.data && orders.last_page"
                  :page-count="orders.last_page"
                  :click-handler="paginate"
                  :prev-text="$trans('Previous')"
                  :next-text="$trans('Next')"
                  :page-class="'page-item'"
                  :prev-class="'page-item'"
                  :next-class="'page-item'"
                  :page-link-class="'page-link'"
                  :prev-link-class="'page-link'"
                  :next-link-class="'page-link'"
                  :container-class="'pagination'">
        </paginate>
      </slot>
      <slot v-else>{{$trans('No orders exist')}}</slot>
    </div>

    <modal v-bind:width="'1000px'" v-if="detailForm">
      <slot slot="body">
        <h5 class="mb-3">
          {{$trans('Order Detail')}}
          <button @click="detailForm = false" class="close">
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
          </button>
        </h5>
        <hr>
        <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
          {{alert.message}}
        </div>
        <div v-if="order" class="row">
            <div class="col-sm-8">
              <table class="table table-striped">
                <tr>
                  <th>{{$trans('Order ID')}}</th>
                  <td>{{order._id}}</td>
                </tr>
                <tr>
                  <th>{{$trans('Status')}}</th>
                  <td>
                    <span v-if="order.status == -3" class="badge badge-primary badge-pill returned">{{ $trans('Returned') }}</span>
                    <span v-if="order.status == -2" class="badge badge-warning badge-pill returning">{{ $trans('Returning') }}</span>
                    <span v-if="order.status == -1" class="badge badge-danger badge-pill cancel">{{ $trans('Cancel') }}</span>
                    <span v-if="order.status == 0" class="badge badge-default badge-pill initialization">{{ $trans('Initialization') }}</span>
                    <span v-if="order.status == 1" class="badge badge-info badge-pill processing">{{ $trans('Processing') }}</span>
                    <span v-if="order.status == 2" class="badge badge-success badge-pill received">{{ $trans('Received') }}</span>
                    <span v-if="order.status == 3" class="badge badge-success badge-pill on-delivery">{{ $trans('On Delivery') }}</span>
                    <span v-if="order.status == 4" class="badge badge-success badge-pill delivered">{{ $trans('Delivered') }}</span>
                    <span v-if="order.status == 5" class="badge badge-success badge-pill completed">{{ $trans('Completed') }}</span>
                  </td>
                </tr>
                <tr>
                  <th>{{$trans('Shipper')}}</th>
                  <td>
                    <slot v-if="order.shipper">
                      {{order.shipper.username}}
                    </slot>
                    <slot v-else>-</slot>
                  </td>
                </tr>
                <tr>
                  <th>{{$trans('Cod amount')}}</th>
                  <td>
                    <vue-numeric
                        currency-symbol-position="suffix"
                        currency="PHP"
                        v-bind:value="order.cod_amount"
                        v-bind:read-only="true"
                        v-bind:precision="0">
                    </vue-numeric>
                  </td>
                </tr>
                <tr>
                  <th>{{$trans('Fee Payer')}}</th>
                  <td>{{order.fee_payer}}</td>
                </tr>
                <tr>
                  <th style="vertical-align: top">{{$trans('Pickup')}}</th>
                  <td>
                    {{ order.pickup_address.name }} - {{ order.pickup_address.phone }}<br>
                    {{ order.pickup_address.address }} {{ order.pickup_address.place }}
                  </td>
                </tr>
                <tr>
                  <th style="vertical-align: top;">{{$trans('Destination')}}</th>
                  <td>
                    {{ order.destination_address.name }} - {{ order.destination_address.phone }}<br>
                    {{ order.destination_address.address }} {{ order.destination_address.place }}
                  </td>
                </tr>
                <tr>
                  <th style="vertical-align: top;">{{$trans('Packages')}}</th>
                  <td>
                    <table class="table">
                      <thead>
                      <tr>
                        <th>{{$trans('Name')}}</th>
                        <th>{{$trans('Type')}}</th>
                        <th>{{$trans('Value')}}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(pack,p) in order.packages" :key="p">
                        <td>{{pack.name}}</td>
                        <td>{{pack.type}}</td>
                        <td>{{pack.value}}</td>
                      </tr>
                      </tbody>
                    </table>

                  </td>
                </tr>
                <tr>
                  <th style="vertical-align: top;">{{$trans('Cost')}}</th>
                  <td>
                    <table class="table table-hover table-custom spacing5">
                      <tbody>
                      <tr v-for="(cost,c) in order.delivery_fee.detail" :key="c">
                        <td>{{cost.name}}</td>
                        <td>
                          <vue-numeric
                              currency-symbol-position="suffix"
                              currency="PHP"
                              v-bind:value="cost.amount"
                              v-bind:read-only="true"
                              v-bind:precision="0">
                          </vue-numeric>
                        </td>
                      </tr>
                      </tbody>
                      <tfoot>
                      <tr>
                        <td><strong>{{$trans('Total')}}:</strong></td>
                        <td>
                          <vue-numeric
                              currency-symbol-position="suffix"
                              currency="PHP"
                              v-bind:value="order.delivery_fee.total"
                              v-bind:read-only="true"
                              v-bind:precision="0">
                          </vue-numeric>
                        </td>
                      </tr>
                      </tfoot>
                    </table>
                  </td>
                </tr>
                <tr>
                  <th>{{$trans('Note')}}</th>
                  <td>{{order.note}}</td>
                </tr>
                <tr>
                    <th style="vertical-align: top">{{$trans('Tracking Histories')}}</th>
                    <td>
                        <slot v-if="order.tracking_histories">
                            <span v-for="(log,l) in order.tracking_histories" :key="l" class="d-block">{{log}}</span>
                        </slot>
                    </td>
                </tr>
              </table>
            </div>
            <div class="col-sm-4">
              <button @click="print('delivery-receipt')" class="btn btn-sm btn-primary mr-3">{{$trans('Print')}}</button>
              <router-link class="btn btn-sm btn-info" :to="'/tracking/'+order._id">{{$trans('Tracking')}}</router-link>
              <hr>
              <div class="form-actions mb-4">
                <slot v-if="order.status == 0 || order.status == 1">
                  <button @click="confirm(order,'cancel')" class="btn btn-danger mr-2">
                    <i v-if="processActions" class="fa fa-spinner fa-spin"></i>
                    {{$trans('Cancel')}}
                  </button>
                </slot>
                <button v-if="order.status == 4" @click="confirm(order,'completed')" class="btn btn-success mr-2">
                  <i v-if="processActions" class="fa fa-spinner fa-spin"></i>
                  {{$trans('Completed')}}
                </button>
              </div>
            </div>
            <div style="display: none">
              <div id="delivery-receipt" class="delivery-receipt">
                <div class="container">
                  <div class="print-page">
                    <div class="print-head">
                      <div class="row">
                        <div class="col-sm-6">
                          <div class="logo">
                            <img :src="require('../assets/logo.png')" alt="">
                          </div>
                          <div class="company-info">
                            <span>{{ company_info.address }}</span>
                            <span><strong>Phone:</strong> {{ company_info.phone }}</span>
                            <span><strong>Email:</strong> {{ company_info.email }}</span>
                            <span><strong>Website:</strong> {{ company_info.website }}</span>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="head-info text-right">
                            <span class="date">{{ order.created_at }}</span>
                            <span class="bar-code">
                                <barcode v-bind:value="order._id"
                                         v-bind:fontSize="14" v-bind:displayValue="true"
                                         v-bind:textAlign="'left'" v-bind:height="40" :width="1" :flat="true">
                                    Show this if the rendering fails.
                                </barcode>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="print-title">
                      <span class="text">{{$trans('Delivery Note')}}</span>
                    </div>

                    <div class="print-shipping">
                      <div class="row">
                        <div class="col-sm-6">
                          <div class="address">
                            <span><strong>{{$trans('Pickup')}}:</strong></span>
                            <span>{{ order.pickup_address.address }}, {{ order.pickup_address.place }}</span>
                            <span><strong>Name:</strong> {{ order.pickup_address.name }}</span>
                            <span><strong>Phone:</strong> {{ order.pickup_address.phone }}</span>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="address">
                            <span><strong>{{$trans('Destination')}}:</strong></span>
                            <span>{{ order.destination_address.address }}, {{ order.destination_address.place }}</span>
                            <span><strong>Name:</strong> {{ order.destination_address.name }}</span>
                            <span><strong>Phone:</strong> {{ order.destination_address.phone }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="print-body">
                      <table class="table table-striped">
                        <thead>
                        <tr>
                          <th>{{$trans('Package name')}}</th>
                          <th>{{$trans('Type')}}</th>
                          <th>{{$trans('Value')}}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(pack,p) in order.packages" :key="p">
                          <td>{{ pack.name }}</td>
                          <td>{{ pack.type }}</td>
                          <td>{{ pack.value }}</td>
                        </tr>
                        </tbody>
                      </table>
                      <div>
                        <strong>{{$trans('Total Costs')}}: </strong>
                        <vue-numeric
                            currency-symbol-position="suffix"
                            currency="PHP"
                            v-bind:value="order.delivery_fee.total"
                            v-bind:read-only="true"
                            v-bind:precision="0">
                        </vue-numeric>
                      </div>
                      <div>
                        <strong>{{$trans('COD amount')}}: </strong>
                        <vue-numeric
                            currency-symbol-position="suffix"
                            currency="PHP"
                            v-bind:value="order.cod_amount"
                            v-bind:read-only="true"
                            v-bind:precision="0">
                        </vue-numeric>
                      </div>
                      <div>
                        <strong>{{$trans('Note')}}: </strong>
                        {{ order.note }}
                      </div>
                    </div>
                    <div class="print-footer">
                      <div class="row">
                        <div class="col-sm-4">
                          <div class="signature">
                            {{$trans('Receiver')}}:
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </slot>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
import Pusher from 'pusher-js';

export default {
  name: "Order",
  data: function () {
    return {
      orders: null,
      filterQuery: {
        page: 1,
        filterby_date: 'all',
        status: 'all',
      },
      detailForm:false,
      alert:null,
      order_detail:null,
      addForm:false,
      process:false,
      addFields:{

      },
      errors:{},
      order:null,
      processActions:false
    }
  },
  methods: {
    filter: function () {
      this.filterQuery.page = 1;
      this.index();
    },
    paginate: function (pageNum) {
      this.filterQuery.page = pageNum;
      this.index();
    },
    index: function () {
      axios({url: this.$root.$data.api_url + '/api/customer/orders', params: this.filterQuery, method: 'GET'})
          .then(resp => {
            this.orders = resp.data;
          })
          .catch(err => {
            console.log(err)
          })
    },
    detail: function (order) {
        axios({url: this.$root.$data.api_url + '/api/customer/orders/'+order._id, method: 'GET'})
          .then(resp => {
            this.order = resp.data.data;
            this.detailForm = true;
          })
          .catch(err => {
            console.log(err)
          })
    },
    print:function (id) {
      this.$htmlToPaper(id);
    },
    store:function (){

    },
    confirm:function (order,action){
      if (this.processActions == false) {
        var actionConfirm = confirm("Are you sure?");
        if (actionConfirm === false) {
          this.processActions = false;
          return false;
        }
        this.processActions = true;
        this.errors = {}
        var data = {
          action: action
        }
        axios({url: this.$root.$data.api_url + '/api/customer/orders/confirm/'+order._id, data:data,method: 'POST'})
            .then(resp => {
              let error_code = resp.data.error_code;
              if( error_code == 0){
                this.index();
                this.detail(resp.data.data)
              }else{
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.processActions = false;
            })
            .catch(err => {
              console.log(err)
              this.processActions = false;
            })
      }
    }
  },
  created() {
    let pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
      cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
    });
    const channel = pusher.subscribe('order-channel');
    channel.bind('order-event',  (e)=> {
      if (this.orders && this.orders.data){
        if( e.action=='created'){
          this.orders.data.unshift(e.order);
        }else{
          var arr = this.orders.data;
          var tmp_arr = [];
          var current_id = e.order._id;
          arr.forEach(function(el, index) {
            if(current_id == el._id){
              tmp_arr[index] = e.order;
            }else{
              tmp_arr[index] = el;
            }
          });
          this.orders.data = tmp_arr;
        }
      }
    });
    this.index()
  },
  computed: {
    company_info:function (){
      return this.$root.$data.global_settings.company_info
    }
  },

}
</script>

<style scoped>

</style>